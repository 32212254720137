import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Testimonials() {
  return (
    <section id="testimonials">
      <div className="text-container">
        <div className="row">
          <div className="two columns header-col">
            <h1><span><FormattedMessage id="testimonials.title" /></span></h1>
          </div>
          <div className="ten columns flex-container">
            <ul className="slides">
              <li>
                <blockquote>
                  <p>
                    <FormattedMessage id="testimonials.cdg" />
                  </p>
                  <cite>Christian Dang • E8 Partenaires</cite>
                </blockquote>
              </li>
              <li>
                <blockquote>
                  <p>
                    <FormattedMessage id="testimonials.ajt" />
                  </p>
                  <cite>Alexander Janet • Shuttlerock</cite>
                </blockquote>
              </li>
              <li>
                <blockquote>
                  <p>
                    <FormattedMessage id="testimonials.agd" />
                  </p>
                  <cite>Adrien Gadaud • Commanders Act</cite>
                </blockquote>
              </li>
              <li>
                <blockquote>
                  <p>
                    <FormattedMessage id="testimonials.pcn" />
                  </p>
                  <cite>Pierre Cren • MOMA</cite>
                </blockquote>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

