import { AppContext } from '@yeutech/app-context';
import React, { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import formatDistance from 'date-fns/formatDistance';

export const MAX = 4;

export default function Tweets(props) {
  const { locale, locales } = useContext(AppContext);
  const [tweets, setTweets] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    (async () => {
      const response = await fetch('/tweets');
      if (response.status !== 200) {
        const err = await response.json();
        setError(err);
        return;
      }
      const newTweets = await response.json();
      setTweets(newTweets);
      setError(null);
    })();
  }, []);

  return (
    <div className="widget widget_tweets">
      <h4 className="widget-title">
        <FormattedMessage id="twitter.latest" />
      </h4>
      {error ? (
        <div className="text text-error"><FormattedMessage id="twitter.down" /></div>
      ) : tweets ? (
        <ul id="twitter">
          {tweets.map((tweet, i) => locale.includes(tweet.lang) && i < MAX && (
            <li key={tweet.id}>
              <span>
                {tweet.text}
              </span>
              <div>
                <a
                  href={tweet.urls.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {tweet.urls.link}
                </a>
              </div>
              <b>
                <a href={tweet.urls.link}>
                  {formatDistance(new Date(tweet.created_at), new Date(), {
                    locale: locales[locale],
                    addSuffix: true,
                  })}
                </a>
              </b>
            </li>
          ))}
        </ul>
      ) : (
        <i className="fa fa-spin fa-spinner" />
      )}
    </div>
  );
}
