import { AppContext } from '@yeutech/app-context';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import $ from 'jquery';
import Tweets from './widget/Tweets';

export default injectIntl(function Contact({
  resumeData,
  data,
  intl,
}) {
  const [isSending, setIsSending] = useState(false);
  const { locale } = useContext(AppContext);

  const {
    name,
    address,
    // phone,
    // email,
  } = resumeData || {};

  const handleChange = useCallback(() => {

  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    (async () => {
      $('#image-loader').fadeIn();
      $('#message-warning').fadeOut();
      setIsSending(true);
      const {
        contactName,
        contactEmail,
        contactSubject,
        contactMessage,
      } = e.currentTarget;
      try {
        await fetch('/contact', {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': locale,
          }),
          body: JSON.stringify({
            contactName: contactName.value.trim(),
            contactEmail: contactEmail.value.trim(),
            contactSubject: contactSubject.value.trim(),
            contactMessage: contactMessage.value.trim(),
          }),
        });
        $('#image-loader').fadeOut();
        $('#contactForm').fadeOut();
        $('#message-warning').hide();
        $('#message-success').fadeIn();
        setIsSending(false);
      } catch (err) {
        $('#image-loader').fadeOut();
        $('#message-warning').fadeIn();
        setIsSending(false);
      }
    })();
  }, [locale]);

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span><FormattedMessage id="contact.title" /></span>
          </h1>
        </div>
        <div className="ten columns">
          <p className="lead"><FormattedMessage id="contact.title" /></p>
        </div>
      </div>
      <div className="row">
        <div className="eight columns">
          <form
            action=""
            method="post"
            id="contactForm"
            name="contactForm"
            onSubmit={handleSubmit}
          >
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  <FormattedMessage id="contact.name" /> <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={handleChange}
                  required
                  pattern=".{3,}"
                  title={intl.formatMessage({ id: 'contact.3charsMin' })}
                />
              </div>
              <div>
                <label htmlFor="contactEmail">
                  <FormattedMessage id="contact.email" /> <span className="required">*</span>
                </label>
                <input
                  type="email"
                  defaultValue=""
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  onChange={handleChange}
                  required
                  pattern=".{5,}"
                  title={intl.formatMessage({ id: 'contact.5charsMin' })}
                />
              </div>
              <div>
                <label htmlFor="contactSubject"><FormattedMessage id="contact.subject" /></label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={handleChange}
                  pattern=".{0}|.{8,}"
                  title={intl.formatMessage({ id: 'contact.0or8charsMin' })}
                />
              </div>
              <div>
                <label htmlFor="contactMessage">
                  <FormattedMessage id="contact.message" /> <span className="required">*</span>
                </label>
                <textarea
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="contactMessage"
                  minLength="10"
                  required
                />
              </div>
              <div>
                <button
                  disabled={isSending}
                  className="submit"
                >
                  <FormattedMessage id="contact.submit" />
                </button>
                <span id="image-loader">
                  <img alt="loading" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>
          <div id="message-warning">
            <FormattedMessage id="contact.error" />
          </div>
          <div id="message-success">
            <i className="fa fa-check" />{'\xa0'}
            <FormattedMessage id="contact.success" />
            <br />
          </div>
        </div>
        <aside className="four columns footer-widgets">
          <div className="widget widget_contact">
            <h4><FormattedMessage id="contact.address" /></h4>
            <p className="address">
              {name}<br />
              {address && (
                <>
                  {address.street} <br />
                  {address.city}, {address.state} {address.zip}<br />
                </>
              )}
              {/*<span>{phone}</span>*/}
            </p>
          </div>
          <Tweets />
        </aside>
      </div>
    </section>
  );
});
