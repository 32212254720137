import { AppContext } from '@yeutech/app-context';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import fr from 'react-phone-input-2/lang/fr.json';
import $ from 'jquery';

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default injectIntl(function FormResume({
  intl,
}) {
  const { locale } = useContext(AppContext);
  const [resumeType, setResumeType] = useState(undefined);
  const [isSending, setIsSending] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    if (resumeType === undefined) {
      $('#form-resume-mode').hide();
    }
  }, [resumeType]);

  const onSetResumeType = useCallback((type) => {
    setResumeType(type);
    $('#form-resume-type').fadeOut(200, () => {
      $('#form-resume-mode').fadeIn();
    });
  }, [setResumeType]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    (async () => {
      setIsSending(true);
      const {
        contactEmail,
      } = e.currentTarget;
      const email = contactEmail.value.trim();
      const inputNumber = e.currentTarget.getElementsByClassName('form-control')[0].value.replace(/ /g, '');
      try {
        const response = await fetch('/resume/send', {
          method: 'POST',
          headers: new Headers({
            Accept: 'application/json',
            'Accept-Language': locale,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify({
            email,
            mobile: inputNumber,
          }),
        });
        $('#form-resume-mode').fadeOut(200, () => {
          if (response.status === 412) {
            $('#resume-message-too-many-request').fadeIn();
          } else {
            $('#resume-message-success').fadeIn();
          }
        });
      } catch (err) {
        $('#form-resume-mode').fadeOut(200, () => {
          $('#resume-message-warning').fadeIn();
        });
      }
    })();
  }, [locale]);

  const handleFormChange = useCallback((e) => {
    let isValid = false;
    const {
      contactEmail,
    } = e.currentTarget;
    const email = contactEmail.value.trim();
    const inputNumber = e.currentTarget.getElementsByClassName('form-control')[0].value.replace(/ /g, '');
    if (email.length > 0 && validateEmail(email)) {
      isValid = true;
    } else if (inputNumber.length > 0 && inputNumber.length > 9) {
      isValid = true;
    }
    setIsValidForm(isValid);
  }, [setIsValidForm]);

  return (
    <form
      action=""
      method="post"
      id="getResumeForm"
      name="getResumeForm"
      onSubmit={handleSubmit}
      onChange={handleFormChange}
    >
      <div id="form-resume-type">
        <h3>
          <FormattedMessage id="about.chooseResume" />
        </h3>
        <button
          type="button"
          onClick={() => onSetResumeType('CTO')}
        >
          <FormattedMessage id="hero.position.CTO" />
        </button>
        <button
          title={intl.formatMessage({ id: 'about.resumeNotAvailable' })}
          type="button"
          onClick={() => onSetResumeType('devOps')}
          disabled
        >
          <FormattedMessage id="hero.position.devOps" />
        </button>
      </div>
      <div id="form-resume-mode">
        <label htmlFor="contactEmail">
          <FormattedMessage id="contact.email" />
        </label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
          size="35"
          name="contactEmail"
          pattern=".{0}|.{5,}"
          title={intl.formatMessage({ id: 'contact.0or5charsMin' })}
        />
        <h6 className="text-center"><FormattedMessage id="about.or"/></h6>
        <label htmlFor="contactPhone">
          <FormattedMessage id="contact.mobile" />
        </label>
        <PhoneInput
          name="contactPhone"
          country={'fr'}
          value={phone}
          onChange={setPhone}
          preferredCountries={[
            'fr',
            'gb',
            'at',
            'de',
            'nz',
          ]}
          regions={['eu-union', 'north-america', 'oceania']}
          localization={locale === 'fr' ? fr : undefined}
          isValid={(inputNumber, country, countries) => countries.some((country) => {
            return (inputNumber.startsWith(country.dialCode) || country.dialCode.startsWith(inputNumber)) && (inputNumber.length < 4 || inputNumber.length > 9);
          })}
        />
        <button
          type="submit"
          disabled={!isValidForm || isSending}
        >
          <i className={`fa ${isSending ? 'fa-spin fa-spinner' : 'fa-envelope'}`} />{'\xa0\xa0\xa0\xa0'}
          <FormattedMessage id="about.sendResume" />
        </button>
      </div>
      <div>
        <div id="resume-message-success">
          <br />
          <i className="fa fa-check" />{'\xa0'}
          <FormattedMessage id="about.resumeSendSuccess" />
        </div>
        <div id="resume-message-warning">
          <br />
          <FormattedMessage id="about.resumeSendFail" />
        </div>
        <div id="resume-message-too-many-request">
          <br />
          <FormattedMessage id="about.resumeSendFailTooManyRequest" />
          <br />
          <FormattedMessage id="about.resumeSendFailTooManyRequestUseForm" />
        </div>
      </div>
    </form>
  );
});
