import React from 'react';
import About from '../components/About';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Resume from '../components/Resume';
import Testimonials from '../components/Testimonials';

export default function OnePage({
  data,
  resumeData,
  locale,
}) {
  return (
    <div className="App">
      <Header
        data={data}
        resumeData={resumeData?.main}
        locale={locale}
      />
      <About
        data={data}
        resumeData={resumeData?.main}
        locale={locale}
      />
      <Resume
        data={data}
        resumeData={resumeData?.resume}
        locale={locale}
      />
      {/*<Portfolio*/}
      {/*  data={data}  */}
      {/*  resumeData={resumeData.portfolio} */}
      {/*/>*/}
      <Testimonials
        data={data}
        resumeData={resumeData?.testimonials}
        locale={locale}
      />
      <Contact
        data={data}
        resumeData={resumeData?.main}
        locale={locale}
      />
      <Footer
        data={data}
        resumeData={resumeData?.main}
        locale={locale}
      />
    </div>
  );
}
