import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Resume({
  locale,
  data,
}) {
  if (data) {
    var {
      educations,
      positions,
      skills: simpleSkills,
    } = data[locale];
    var education = educations.map((education) => {
      return (
        <div key={education.title}>
          <h3>{education.title}</h3>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.year_end}</em></p>
          <p>{education.description}</p>
        </div>
      );
    });
    var work = positions.map((work) => {
      return (
        <div key={work.company_name}>
          <div className="row">
            <h3 className="column">{work.company_name}</h3>
            <h6 className="column info">{work.location}</h6>
          </div>
          <p className="info">{work.title}<span>&bull;</span> <em className="date">{`${work.date_start} - ${work.date_end}`}</em></p>
          {work.description && <p style={{ whiteSpace: "pre-line" }}>{work.description.replace('see less', '')}</p>}
        </div>
      );
    });

    var skills = simpleSkills.map((skill) => {
      var className = 'bar-expand ' + skill.title.toLowerCase();
      return (
        <li key={skill.title}>
          <span style={{ width: `${skill.count + 75 + (Math.floor(Math.random() * 10) + 1)}%` }} className={className} />
          <em>{skill.title}</em>
        </li>
      );
    });
  }
  return (
    <section id="resume">
      <div className="row work">
        <div className="three columns header-col">
          <h1><span><FormattedMessage id="work.work" /></span></h1>
        </div>
        <div className="nine columns main-col">
          {work}
        </div>
      </div>
      <div className="row skill">
        <div className="three columns header-col">
          <h1><span><FormattedMessage id="work.skills" /></span></h1>
        </div>
        <div className="nine columns main-col">
          <p><FormattedMessage id="work.skillTitle" /></p>
          <div className="bars">
            <ul className="skills">
              {skills}
            </ul>
          </div>
        </div>
      </div>
      <div className="row education">
        <div className="three columns header-col">
          <h1><span><FormattedMessage id="work.education" /></span></h1>
        </div>
        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">
              {education}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
