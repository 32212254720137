import AppContextProvider from '@yeutech/app-context';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ResumePage from './pages/ResumePage';

import {
  i18nProvider,
  detectedLocale,
  locales,
  defaultLocale,
} from './translations/i18nProvider';

import OnePage from './pages/OnePage';
import ContextIntlProvider from './components/ContextIntlProvider';

import { env } from './config';

const { gaId } = env;

export default function App(props) {
  const [data, setData] = useState(null);
  const [resumeData, setResumeData] = useState(null);

  const [locale, setLocale] = useState(detectedLocale);
  useEffect(() => {
    if (process.env.NODE_ENV !== 'test') {
      ReactGA.initialize(gaId);
      ReactGA.pageview(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch('/linkedinData');
        const newData = await res.json();
        setData(newData);
        const res2 = await fetch('/resumeData.json');
        const newResumeData = await res2.json();
        setResumeData(newResumeData);
      } catch (err) {
        console.log(err);
        alert('Something went wrong, come back later');
      }
    })();
  }, []);

  return (
    <AppContextProvider
      locale={locale}
      locales={locales}
      setLocale={setLocale}
    >
      <ContextIntlProvider
        defaultLocale={defaultLocale}
        i18nProvider={i18nProvider}
      >
        <Helmet
          htmlAttributes={{ lang: locale }}
        />
        <Router>
          <Switch>
            <Route path="/resume">
              <ResumePage />
            </Route>
            <Route path="/">
              <OnePage
                data={data}
                resumeData={resumeData}
                locale={locale}
              />
            </Route>
          </Switch>
        </Router>
      </ContextIntlProvider>
    </AppContextProvider>
  );
}
