/**
 * @public
 * @name urls
 * @description
 * An object with external urls
 * @type {{
 *   logout: string,
 *   ping: string,
 *   api: string,
 *   login: string
 * }}
 */
export const urls = {
  api: process.env.REACT_APP_URL_API,
  login: `${process.env.REACT_APP_URL_API}/login`,
  logout: `${process.env.REACT_APP_URL_API}/logout`,
  ping: `${process.env.REACT_APP_URL_API}/ping`,
};

/**
 * @public
 * @name oAuthClient
 * @description
 * OAuth client configuration
 * @type {{
 *   authorizationGrants: string[],
 *   redirectUri: string,
 *   authorizationUri: string,
 *   clientId: string,
 *   clientSecret: string,
 *   accessTokenUri: string,
 *   scopes: string[]
 * }}
 */
export const oAuthClient = {
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  clientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  accessTokenUri: process.env.REACT_APP_OAUTH_ACCESS_TOKEN_URI,
  authorizationUri: process.env.REACT_APP_OAUTH_AUTHORIZATION_URI,
  authorizationGrants: (process.env.REACT_APP_OAUTH_AUTHORIZATION_GRANTS || '').split(','),
  redirectUri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
  scopes: (process.env.REACT_APP_OAUTH_SCOPES || '').split(','),
};

/**
 * @public
 * @name publicUrls
 * @description
 * Other urls for external and public accessed websites
 * @type {{
 *   icimatin: string
 * }}
 */
export const publicUrls = {
  icimatin: process.env.REACT_APP_PUBLIC_URL_ICIMATIN,
};

export const gaId = process.env.REACT_APP_GA_ID;

