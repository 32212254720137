import React, { useContext } from 'react';
import { AppContext } from '@yeutech/app-context';
import { FormattedMessage, injectIntl } from 'react-intl';
import Typing from 'react-typing-animation';
import TrackVisibility from 'react-on-screen';

const TypingPosition = ({
  isVisible,
  locale,
  positionDevOps,
  positionCTO,
}) => {
  return isVisible && (
    <Typing loop className="inline" key={locale}>
      <span>
        {positionDevOps}
      </span>
      <Typing.Delay ms={1000} />
      <Typing.Backspace count={positionDevOps.length + 1} />
      <span>
        {positionCTO}
      </span>
      <Typing.Delay ms={1000} />
      <Typing.Backspace count={positionCTO.length + 1} />
    </Typing>
  );
}
export default injectIntl(function Header({
  data,
  resumeData,
  intl,
  locale,
}) {
  const { setLocale } = useContext(AppContext);
  const {
    name,
    address,
    social,
  } = resumeData || {};

  const positionDevOps = intl.formatMessage({ id: 'hero.position.devOps' });
  const positionCTO = intl.formatMessage({ id: 'hero.position.CTO' });

  return (
    <header id="home">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title={intl.formatMessage({ id: 'nav.show' })}>
          <FormattedMessage id="nav.show" />
        </a>
        <a className="mobile-btn" href="#home" title={intl.formatMessage({ id: 'nav.hide' })}>
          <FormattedMessage id="nav.hide" />
        </a>
        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              <FormattedMessage id="nav.home" />
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              <FormattedMessage id="nav.about" />
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#resume">
              <FormattedMessage id="nav.resume" />
            </a>
          </li>
          {/*<li>*/}
          {/*  <a className="smoothscroll" href="#portfolio">*/}
          {/*    <FormattedMessage id="nav.works" />*/}
          {/*  </a>*/}
          {/*</li>*/}
          <li>
            <a className="smoothscroll" href="#testimonials">
              <FormattedMessage id="nav.testimonials" />
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              <FormattedMessage id="nav.contact" />
            </a>
          </li>
          <li>
            <a
              href="https://blog.kop.ax"
              target="_blank"
              rel="noopener noreferrer"
              className="mr20"
            >
              Blog
            </a>
          </li>
          <li>
            <button type="button" onClick={() => setLocale('fr')} className="lang-btn">
              <i className={locale === 'fr' ? 'active' : ''}>FR</i>
            </button>
          </li>
          <li>
            <button type="button" onClick={() => setLocale('en')} className="lang-btn">
              <i className={locale === 'en' ? 'active' : ''}>EN</i>
            </button>
          </li>
        </ul>
      </nav>
      <div className="row banner">
        <div className="banner-text">
          <h1 className="responsive-headline">
            <FormattedMessage id="header.name" values={{ name }} />.
          </h1>
          {address && (
            <div>
              <h3 className="inline">
                <>
                  <FormattedMessage id="header.intro" values={{ city: address.city }} />
                  {' '}
                  <FormattedMessage id="header.intro2" />{' '}
                </>
              </h3>
              <h3 className="inline">
                <TrackVisibility partialVisibility tag="span">
                  <TypingPosition
                    locale={locale}
                    positionDevOps={positionDevOps}
                    positionCTO={positionCTO}
                  />
                </TrackVisibility>
                .
              </h3>
            </div>
          )}
          <h3><FormattedMessage id="hero.description" />.</h3>
          <hr />
          <ul className="social">
            {social && social.map((network) => {
              return <li key={network.name}><a href={network.url}><i className={network.className} /></a></li>;
            })}
          </ul>
        </div>
      </div>
      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle" />
        </a>
      </p>
    </header>
  );
});
