import React from 'react';

export default function Footer({
  data,
  resumeData,
}) {
  let networks;

  if (resumeData) {
    networks = resumeData.social.map((network) => {
      return (
        <li key={network.name}>
          <a href={network.url}>
            <i className={network.className} />
          </a>
        </li>
      );
    });
  }
  return (
    <footer>
      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">
            {networks}
          </ul>
          <ul className="copyright">
            <li>&copy; Copyright 2020 Dimitri Kopriwa</li>
          </ul>
        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open" /></a>
        </div>
      </div>
    </footer>
  );
}
