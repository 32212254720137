import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import FormResume from './widget/FormResume';

export default injectIntl(function About({
  data = {},
  resumeData,
  intl,
  locale,
}) {
  const [visible, setVisible] = useState(false);

  if (data) {
    var {
      profile: {
        summary,
      },
    } = data[locale];
  }

  const {
    name,
    // phone,
    email,
  } = resumeData || {};
  if (resumeData) {
    var profilepic = 'images/' + resumeData.image;
    var street = resumeData.address.street;
    var city = resumeData.address.city;
    var state = resumeData.address.state;
    var zip = resumeData.address.zip;
  }

  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic" src={profilepic}
               alt={intl.formatMessage({ id: 'about.profilePicture' }, { name })} />
        </div>
        <div className="nine columns main-col">
          <h2>
            <FormattedMessage id="about.me" />
          </h2>
          <p>{summary}</p>
          <div className="row">
            <div className="columns contact-details">
              <h2>
                <FormattedMessage id="about.contactDetails" />
              </h2>
              <p className="address">
                <span>{name}</span>
                <br />
                <span>
                  {street}<br />
                  {city} {state}, {zip}
                 </span>
                {/*<br />*/}
                {/*<span>{phone}</span>*/}
                <br />
                <span>{email}</span>
              </p>
            </div>
            <div className="columns download">
              <button type="button" className="button" onClick={() => setVisible(true)}>
                <i className="fa fa-download" />
                <FormattedMessage id="about.getResume" />
              </button>
              <Rodal
                closeOnEsc
                visible={visible}
                onClose={() => setVisible(false)}
                width={340}
              >
                <FormResume key={visible} />
              </Rodal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
